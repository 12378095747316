<template>
  <b-container v-if="this.harvests.length > 0" class="m-0 p-0">
    <!-- Harvests -->
    <hr/> 
    <h4 class="subtitle-form">{{ $t("activities.details.harvest")}}</h4>
    <div class="text-infos bg-white">
      <b-row class="m-0 bg-white">
        <!-- product text -->
        <b-col cols="7" class="subtitle-form change-subtitle-color">{{ $t("harvestStorage.list.product") }}</b-col>
        <!-- quantity text -->
        <b-col cols="5" class="subtitle-form change-subtitle-color">{{ $t("harvestStorage.list.quantity") }}</b-col>
      </b-row>
      <b-row> 
        <!-- product name -->
        <b-col cols="7" ><span class="text-infos whitout-bg" >{{ this.harvests[0].variety_name }}</span></b-col>
        <!-- quantity -->
        <b-col cols="5" class="m-0 p-0"><span class="text-infos whitout-bg" >{{ this.harvests[0].quantity }} {{ this.harvests[0].unit}}</span></b-col>
      </b-row> 
      <b-row class="m-0 bg-white">
        <!-- warehouse text -->
        <b-col cols="12" class="subtitle-form change-subtitle-color">{{ $t("harvestStorage.list.warehouse") }}</b-col>
      </b-row>
      <b-row> 
        <!-- warehouse name -->
        <b-col cols="12" ><span class="text-infos whitout-bg" >{{ this.harvests[0].warehouse_name }}</span></b-col>
      </b-row> 
    </div>

  </b-container>
</template>

<script>
import HarvestModule from "@/store/modules/harvestsStorage";
import { mapGetters } from "vuex";

export default {
  name: "harvest",
  props: {
    useForm: {
      type: String,
      require: true,
    },
  },
  created: async function() {
    await this.$store.dispatch("harvestStorage/getHarvestsStorage", { idActivity: this.$route.params.activity_id });
  },
  data() {
    return {
      page: this.useForm,
    };
  },
  beforeCreate() {
    const store = this.$store;
    if (store && store.state) {
      if (!store.state["harvestsStorage"]) {
        store.registerModule("harvestsStorage", HarvestModule);
      }
    }
  },
  computed: {
    ...mapGetters({harvests: "harvestStorage/harvests"}),
  },
};
</script>

<style lang="scss" scoped>
/**
 * Change the subtitle color.
 */
.change-subtitle-color {
  color: $primary-color;
}
</style>
